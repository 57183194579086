<template>
  <div>
    <section class="banner-two2 img mt-5 banner">
      <div class="container-fluid text-center text-align">
        <!-- <div style="transform: rotate(180deg);"> -->
        <nav class="three-nav1">
          <ul class="three-nav1 navigation-list-item-blk" :class="{ 'show-navigation-list': seen }">
            <li class="three-nav1" @click="joinDoctorsCommunity()">
              <div>
                <img src="~@/assets/images/man-man.png" style="width: 40px;">
              </div>
            </li>
            <li class="three-nav1" @click="joinPatientGroups()">
              <div>
                <img src="~@/assets/images/community.png" style="width: 40px;">
              </div>
            </li>
            <li class="three-nav1" @click="accessReliableInformation()">
              <div>
                <img src="~@/assets/images/bookcreative.png" style="width: 40px;">
              </div>
            </li>
            <li class="three-nav1" @click="searchClinic()">
              <div>
                <img src="~@/assets/images/hospitallens.png" style="width: 40px;">
              </div>
            </li>
          </ul>
        </nav>


        <div class="text-box">
          <div class="heading-request">
            <h1>Request home consultation</h1>
          </div>
          <div class="request-content">
            <p>Now, get consulttion experience at your home,</p>
            <p>similar to what you would get at a clinic that uses RxIx.</p>
            <p>Please note that we offer home in consultation in select locations
              currently.</p>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </section>
    <section class="banner-two2 img">
      <div class="container-fluid text-center text-align">
        <div class="check-service">
          <h1>
            Check for service in your area
          </h1>
        </div>
        <div class="input-btn">
          <input type="number" id="pincode" name="pincode" placeholder="Your pincode" class="input-box">
          <div>
            <button class="check-btn" :disabled=isDisabled @click="openCheck()">Check</button>
          </div>
        </div>

        <div v-if="isopenCheck">
          <div class="need-content">
            <!-- <p>Regret, we do not have doctors offering home consultation for your pincode./</p> -->
            <p>We have doctors offering home consultation for your pincode.</p>
            <p>Please provide patient details to proceed.</p>
          </div>

          <div class="border-color p-4 radius-patient mt-5">
            <h5 class="text-color taskmanager-title background1">Patient details</h5>

            <div class="row">
              <div class="col-12">
                <label for="exampleFormControlInput1" class="form-label req">Name</label>
                <input class="form-control border" type="text" aria-label="Name" />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="exampleFormControlInput1" class="form-label req">Age (in years)</label>
                <input class="form-control border" type="number" aria-label="Age" />
              </div>
              <div class="col-6">
                <label for="exampleFormControlInput1" class="form-label req">Gender</label>
                <select name="gender" id="gender" class="drp-option">
                  <option value="option1">Select</option>
                </select>
              </div>
            </div>
          </div>
          <div class="border-color p-4 radius-address mt-5">
            <h5 class="text-color taskmanager-title background2">Address</h5>

            <div class="row">
              <div class="col-12">
                <label for="exampleFormControlInput1" class="form-label req">Address where doctor is required</label>
                <input class="form-control pad" type="text" aria-label="Name" />
                <input class="form-control border" type="text" aria-label="Name" />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="exampleFormControlInput1" class="form-label req">Landmark</label>
                <input class="form-control border" type="text" aria-label="Age" />
              </div>
              <div class="col-6">
                <label for="exampleFormControlInput1" class="form-label req">Contact number</label>
                <input class="form-control border" type="number" aria-label="Age" />
              </div>
            </div>
          </div>
          <div class="dis-flex mt-5">
            <div class="border-color reference-main-div">
              <p>Reference check</p>
            </div>
            <div class="border-color reference-sub-div">
              <div class="checkbox">
                <div class="">
                  <input class="form-check-box" type="checkbox" name="condition" id="condition" />
                </div>
                <div class="">
                  <h6 class="form-check-label text-color" for="condition">
                    I wish to take a user reference check. If I am not happy with the 
                    feedback, I will request for an alternate doctor/cancel my request.
                  </h6>
                </div>
              </div>
              <div class="checkbox">
                <div class="">
                  <input class="form-check-box" type="checkbox" name="condition" id="condition" />
                </div>
                <div class="">
                  <h6 class="form-check-label text-color" for="condition">
                    I DO NOT wish to take a user reference check.
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="agree-form1">
            <p class="access-content">
              I agree to share my RxIx records with the doctor who will be assiged for my
              consultation. I authorize RxIx to provide the doctor access to my records.
            </p>
          </div>
          <div class="agree-form2">
            <p class="access-content">
              I understand that I have control over the access to my records and I can share
              or revoke their access, whenever and to without I deem fit.
            </p>
          </div>
          <hr class="hr-borderline" />
          <div class="payment">
            <div>
              <p class="pay-content">The amount payable by you is Rs.xxx/-</p>
            </div>
            <a href="/signup">
            <div>
              <button class="btn-pay" id="show-btn" @click=openPay>Pay</button>
            </div>
          </a>
          </div>
        </div>

      </div>
    </section>
    <!-- <SignUpGeneral @isHide=signupPopupStatus($event) v-if="isopenpay"></SignUpGeneral> -->
  </div>
  <!-- </div> -->
</template>
<script>
// import SignUpGeneral from "../publicPageCard/signupgeneral.vue"
export default {
  components: {
    // SignUpGeneral
  },
  data() {
    return {
      isopenCheck: false,
      // isopenpay: false,
      isDisabled: false,
      seen: true,
    }
  },
  methods: {
    openCheck() {
      this.isopenCheck = true;
      this.isDisabled = true;
    },
    // openPay() {
    //   this.isopenpay = true;
    // },
    // signupPopupStatus(event) {
    //   console.log('event >>>>', event);
    //   if (event) {
    //     this.isopenpay = false;
    //   }
    // },
    joinDoctorsCommunity() {
      window.location.href = "/public/doctorcommunities"; 
    },
    joinPatientGroups() {
      window.location.href = "/public/patientgroups"; 
    },
    searchClinic() {
      window.location.href = "/public/searchclinic"; 
    },
    accessReliableInformation() {
      window.location.href = "/public/patientresources"; 
    }
  }
}
</script>
<style>
.payment {
  display: flex;
  justify-content: center;
}

.input-btn {
  display: flex;
  justify-content: center;

}

input.input-box {
  text-align: center;
  margin-right: 35px;
}

nav.three-nav1 {
  float: right;
  position: fixed;
  right: -3px;
  background: transparent;
}

nav.three-nav1:hover {
  transform: perspective(400px) rotateY(1deg);
}

nav.three-nav1 ul.three-nav1 {
  text-align: center;
  /* margin-top: 60px; */
  margin-top: 30px;
}

@media screen and (max-width:992px) {
  nav.three-nav1 ul.three-nav1 {
    text-align: center;
    margin-top: 0px
  }
}

nav.three-nav1 ul.three-nav1 li.three-nav1 {
  position: relative;
  width: 71px;
  cursor: pointer;
  background: cadetblue;
  text-transform: uppercase;
  transition: all .4s ease-out;
  list-style: none;
}

nav.three-nav1 ul.three-nav1 li:after {
  position: absolute;
  background: white;
  color: crimson;
  top: 0;
  left: 0px;
  width: 70px;
  height: 100%;
  opacity: .5;
  transform: perspective(400px) rotateY(90deg);
  transform-origin: 0 100%;
  transition: all .4s ease-out;
}

nav.three-nav1 ul.three-nav1 li:nth-child(1):after {
  line-height: 88px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(2):after {
  line-height: 88px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(3):after {
  line-height: 85px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(4):after {
  line-height: 70px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(5):after {
  line-height: 85px;
}

nav.three-nav1 ul.three-nav1 li:hover {
  transform: translateX(0px);
  background-color: #fff;
}

nav.three-nav1 ul.three-nav1 li:hover:after {
  opacity: 1;
  transform: perspective(400px) rotateY(90deg) scale(1);
}


nav.three-nav1 ul.three-nav1 li>div {
  display: inline-block;
  padding: 25px 0;
  background: transparent;
}

nav.three-nav1 ul.three-nav1 li.three-nav1 div {
  position: relative;
}


li.navbar-nav-list.hidden-md.hidden-lg.dropdown.xs-design.open-side-menu1 {
  list-style: none;
  position: relative;
  left: 35px;
  bottom: 20px;
}




.col-3 input {
  text-align: center;
}

.taskmanager-title.background1 {
  background: #fff;
  padding: 3px;
  margin-top: -41px;
  width: 135px;
}

.taskmanager-title.background2 {
  background: #fff;
  padding: 3px;
  margin-top: -41px;
  width: 84px;
}

.pay-content {
  float: right;
  color: #45a1a8;
  margin-right: 45px;
}

.hr-borderline {
  width: 43%;
  margin-left: auto;
  margin-right: auto;
}

.btn-pay {
  border: none;
  outline: none;
  background-color: #f6802a;
  border-radius: 12px;
  color: #fff;
  width: 72px;
  height: 34px;
}

.amount-flex {
  display: flex;

}

.agree-form1 {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.agree-form2 {
  display: flex;
  justify-content: center;
}

.access-content {
  color: #359ca6;
  font-size: 13px;
  text-align: left;
  width: 25%
}


.checkbox {
  display: flex;
  align-content: stretch;
  justify-content: flex-start;
}

.form-check-label.text-color {
  font-size: 12px;
  margin-top: 4px;
  color: #666b70 !important;
  padding-left: 8px;
}

.form-check-box {
  margin-left: 5px;
}

.dis-flex {
  display: flex;
  justify-content: center;
}

.border-color.reference-main-div {
  border-color: #416365 !important;
  color: #fff;
  background-color: #34989f;
  height: 74px;
  width: 93px;
  font-size: 15px;
  padding: 10px;
}

.border-color.reference-sub-div {
  border-color: #34989f !important;
  height: 74px;
  width: 383px;
}

input.form-control.border {
  width: 100%;
  height: 30px;
  border-color: #34989f !important;
  border-radius: 0px;
}

input.form-control.pad {
  width: 100%;
  border-color: #34989f !important;
  border-radius: 0px;
  margin-bottom: 7px;
  height: 30px;
}

.col-12 label.form-label.req {
  margin-right: 84px;
}

.drp-option {
  background-color: #fff;
  color: #4d4d4d;
  border-color: #34989f !important;
  width: 204px;
  height: 32px;
  border-radius: 8px;
  font-size: 17px;
}

.border-color.p-4.radius-patient {
  border-radius: 0px;
  width:min-content;
margin: auto;
}

.border-color.p-4.radius-address {
  border-radius: 0px;
  width: min-content;
  margin: auto;
}

.need-content p {
  font-size: 24px;
  color: #240701
}

label.form-label.req {
  color: #00979e;
  text-align: left;
  margin-top: 15px;
  font-size: 14px;
}

button.check-btn {
  color: #fff;
  background-color: #34989f;
  outline: none;
  border: none;
  font-weight: bold;
  border-radius: 12px;
  width: 93px;
  height: 37px;
}

button.check-btn:disabled {
  pointer-events: none;
  background-color: #9bc5c8;
}

.check-service h1 {
  font-size: 31px;
  color: #000;
  padding-bottom: 35px;
  padding-top: 55px;
}

.icon-curve3 {
  width: 100%;
  opacity: 0.5
}

.request-content p {
  font-size: 30px;
  color: #4d4d4d;
}

.heading-request h1 {
  font-size: 45px;
  /* font-weight: bold; */
  color: #000000;
  padding-bottom: 30px;
}

.text-box {
  padding-top: 60px;
  padding-bottom: 56px;
}

section.banner-two2.img.mt-5.banner {
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/cards-banner7.png");
  background-size: 3500px 593px;
  /* transform: rotate(180deg); */

}

.navbar-light .navbar-nav .nav-link {
  color: #00979e;
}
</style>